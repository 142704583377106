// extracted by mini-css-extract-plugin
export var crossPlatformBenefitsSection = "M_gR";
export var crossPlatformBusinessSection = "M_gT";
export var crossPlatformCasesSection = "M_g0";
export var crossPlatformExpertiseSection = "M_gY";
export var crossPlatformIndustriesSection = "M_gS";
export var crossPlatformPreferencesSection = "M_gZ";
export var crossPlatformProcessSection = "M_gV";
export var crossPlatformQuotesSection = "M_g1";
export var crossPlatformServicesSection = "M_gQ";
export var crossPlatformTechStackSection = "M_gW";
export var crossPlatformTechStackSection__callToAction = "M_gX";